import axios from 'axios'

export default {
  actions: {
    restaurantsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants?page=' + data.page
        const response = await axios.get(url)
        commit('setRestaurantsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editRestaurantRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSingleRestaurantData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveRestaurantRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    updateRestaurantSaltRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants/' + data.id + '/salt_update'
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    downloadRestaurantQRCodesTableNumbersRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants/' + data.id + '/download_qr_codes_table_numbers'
        const response = await axios.get(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createRestaurantRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants'
        const response = await axios.post(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteRestaurantRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/restaurants/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    showCitiesRequest: async ({ commit }) => {
      try {
        const url = '/admin/restaurants/create'
        const response = await axios.get(url)
        commit('setCitiesList', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    restaurantsAllRequest: async ({ commit }) => {
      try {
        const url = '/restaurants'
        const response = await axios.get(url)
        commit('setRestaurantsAll', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setRestaurantsData: (state, data) => {
      state.restaurantsData = data.data
      state.restaurantsMeta = data.meta
    },
    setSingleRestaurantData: (state, data) => {
      state.singleRestaurantData = data.restaurant
      state.citiesList = data.cities
    },
    setCitiesList: (state, data) => {
      state.citiesList = data.cities
    },
    setRestaurantsAll: (state, data) => {
      state.restaurantsAll = data.data
    }
  },
  getters: {
    getRestaurantsData: state => state.restaurantsData,
    getRestaurantsMeta: state => state.restaurantsMeta,
    getSingleRestaurantData: state => state.singleRestaurantData,
    getCitiesList: state => state.citiesList,
    getRestaurantsAll: state => state.restaurantsAll
  },
  state: {
    restaurantsData: [],
    restaurantsMeta: {},
    singleRestaurantData: {
      phone: [null, null, null]
    },
    citiesList: [],
    restaurantAll: []
  }
}
