import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/pages/NotFound'
import store from '@/store/index'

import globals from '@/globals'

// Layouts
import Layout2Flex from '@/layout/Layout2Flex'
import LayoutBlank from '@/layout/LayoutBlank'

Vue.use(Router)
Vue.use(Meta)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    console.log('ifNotAuthenticated', store.getters.isAuthenticated)
    next()
    return
  }
  next('/')
}

const ifAuthenticated = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    console.log('isAuthenticated', store.getters.isAuthenticated)
    await next()
    await store.dispatch('profileRequest')
    return
  }
  next('/login')
}

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [{
    path: '/',
    component: Layout2Flex,
    beforeEnter: ifAuthenticated,
    children: [{
      path: '',
      component: () => import('@/components/pages/Home')
    }, {
      path: 'categories',
      component: () => import('@/components/pages/catalog/')
    }, {
      path: 'subcategories',
      component: () => import('@/components/pages/catalog/')
    }, {
      path: 'category-subcategories/:id',
      component: () => import('@/components/pages/catalog/')
    }, {
      path: 'category/:id',
      component: () => import('@/components/pages/catalog/_id/')
    }, {
      path: 'subcategory/:id',
      component: () => import('@/components/pages/catalog/_id/')
    }, {
      path: 'create-category',
      component: () => import('@/components/pages/catalog/_id/')
    }, {
      path: 'create-subcategory',
      component: () => import('@/components/pages/catalog/_id/')
    }, {
      path: 'restaurants',
      component: () => import('@/components/pages/restaurant/')
    }, {
      path: 'restaurant/:id',
      component: () => import('@/components/pages/restaurant/_id/')
    }, {
      path: 'create-restaurant',
      component: () => import('@/components/pages/restaurant/_id/')
    }, {
      path: 'metro-stations',
      component: () => import('@/components/pages/metro-station/')
    }, {
      path: 'metro-station/:id',
      component: () => import('@/components/pages/metro-station/_id/')
    }, {
      path: 'create-metro-station',
      component: () => import('@/components/pages/metro-station/_id/')
    }, {
      path: 'delivery-schedules',
      component: () => import('@/components/pages/delivery-schedule/')
    }, {
      path: 'delivery-schedule/:id',
      component: () => import('@/components/pages/delivery-schedule/_id/')
    }, {
      path: 'create-delivery-schedule',
      component: () => import('@/components/pages/delivery-schedule/_id/')
    }, {
      path: 'cities',
      component: () => import('@/components/pages/city/')
    }, {
      path: 'city/:id',
      component: () => import('@/components/pages/city/_id/')
    }, {
      path: 'create-city',
      component: () => import('@/components/pages/city/_id/')
    }, {
      path: 'products',
      component: () => import('@/components/pages/product/')
    }, {
      path: 'product/:id',
      component: () => import('@/components/pages/product/_id/')
    }, {
      path: 'category-products/:id',
      component: () => import('@/components/pages/product/')
    }, {
      path: 'hidden-product/:id',
      component: () => import('@/components/pages/product/_id/')
    }, {
      path: 'create-product',
      component: () => import('@/components/pages/product/_id/')
    }, {
      path: 'shares',
      component: () => import('@/components/pages/share/')
    }, {
      path: 'share/:id',
      component: () => import('@/components/pages/share/_id/')
    }, {
      path: 'category-shares/:id',
      component: () => import('@/components/pages/share/')
    }, {
      path: 'create-share',
      component: () => import('@/components/pages/share/_id/')
    }, {
      path: 'orders',
      component: () => import('@/components/pages/orders/')
    }, {
      path: 'order/:id',
      component: () => import('@/components/pages/orders/_id/')
    }, {
      path: 'create-order',
      component: () => import('@/components/pages/orders/_id/')
    }, {
      path: 'admin-users',
      component: () => import('@/components/pages/user/')
    }, {
      path: 'app-users',
      component: () => import('@/components/pages/user/')
    }, {
      path: 'admin-user/:id',
      component: () => import('@/components/pages/user/_id/')
    }, {
      path: 'create-admin-user',
      component: () => import('@/components/pages/user/_id/')
    }, {
      path: 'client/:id',
      component: () => import('@/components/pages/user/_id/')
    }, {
      path: 'bonus-program',
      component: () => import('@/components/pages/bonus-program/')
    }, {
      path: 'bonus-program/:id',
      component: () => import('@/components/pages/bonus-program/_id/')
    }, {
      path: 'create-bonus',
      component: () => import('@/components/pages/bonus-program/_id/')
    }, {
      path: 'about-us',
      component: () => import('@/components/pages/content-pages/about-us/')
    }, {
      path: 'about-delivery',
      component: () => import('@/components/pages/content-pages/about-delivery/')
    }, {
      path: 'public-contract',
      component: () => import('@/components/pages/content-pages/public-contract/')
    }, {
      path: 'bonus-account',
      component: () => import('@/components/pages/content-pages/bonus-account/')
    }, {
      path: 'dictionaries/payments',
      component: () => import('@/components/pages/dictionaries/payment/')
    }, {
      path: 'dictionaries/payment/:id',
      component: () => import('@/components/pages/dictionaries/payment/_id/')
    }, {
      path: 'dictionaries/create-payment',
      component: () => import('@/components/pages/dictionaries/payment/_id/')
    }, {
      path: 'settings',
      component: () => import('@/components/pages/settings/')
    }]
  }, {
    path: '/login',
    component: LayoutBlank,
    beforeEnter: ifNotAuthenticated,
    children: [{
      path: '',
      component: () => import('@/components/pages/login/')
    }]
  }, {
    // 404 Not Found page
    path: '*',
    component: NotFound
  }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
