<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo bg-primary">
        <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stop-opacity=".25" offset="0"></stop><stop stop-opacity=".1" offset=".3"></stop><stop stop-opacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a"></linearGradient></defs><path style="fill: #fff;" transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">Pie@City</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <div v-if="getProfileData.role === 'manager'">

        <!-- Home -->
        <sidenav-router-link icon="ion ion-md-desktop" to="/" :exact="true">Главная</sidenav-router-link>

        <!-- Orders -->
        <sidenav-router-link icon="ion ion-ios-basket" to="/orders" :exact="true">Заказы</sidenav-router-link>
      </div>
      <div v-else-if="getProfileData.role === 'content_manager'">
        <!-- Home -->
        <sidenav-router-link icon="ion ion-md-desktop" to="/" :exact="true">Главная</sidenav-router-link>
        <!-- Catalog -->
        <sidenav-menu icon="ion ion-ios-book" :active="isMenuActive('/categories', '/subcategories')" :open="isMenuOpen('/categories', '/subcategories')">
          <template slot="link-text">Каталог</template>
          <sidenav-router-link to="/categories" :exact="true">Категории</sidenav-router-link>
          <sidenav-router-link to="/subcategories" :exact="true">Подкатегории</sidenav-router-link>
        </sidenav-menu>
        <!-- Goods -->
        <sidenav-menu icon="ion ion-md-pizza" :active="isMenuActive('/products')" :open="isMenuOpen('/products')" @click.native="dataRequest">
          <template slot="link-text">Товары</template>
          <template v-for="(category) of categories">
            <sidenav-router-link :key="category.id" :to="'/category-products/' + category.id" :exact="true">
              <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
            </sidenav-router-link>
          </template>
          <sidenav-router-link to="/products" :exact="true">Все товары</sidenav-router-link>
        </sidenav-menu>
        <!-- Shares -->
        <sidenav-menu icon="ion ion-md-pizza" :active="isMenuActive('/shares', '/category-shares')" :open="isMenuOpen('/shares', '/category-shares')" @click.native="dataRequest">
          <template slot="link-text">Акции</template>
          <template v-for="(category) of categories">
            <sidenav-router-link :key="category.id" :to="'/category-shares/' + category.id" :exact="true">
              <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
            </sidenav-router-link>
          </template>
          <sidenav-router-link to="/shares" :exact="true">Все акции</sidenav-router-link>
        </sidenav-menu>
        <!-- Restaurants -->
        <sidenav-router-link icon="ion ion-md-restaurant" to="/restaurants" :exact="true">Рестораны</sidenav-router-link>

        <!-- Metro stations -->
        <sidenav-router-link icon="ion ion-md-train" to="/metro-stations" :exact="true">Станции метро</sidenav-router-link>

        <!-- Delivery schedule -->
        <sidenav-router-link icon="ion ion-md-gift" to="/delivery-schedules" :exact="true">Доставка</sidenav-router-link>

        <!-- Cities -->
        <sidenav-router-link icon="ion ion-md-map" to="/cities" :exact="true">Города</sidenav-router-link>

        <!-- Content pages -->
        <sidenav-menu icon="ion ion-md-clipboard" :active="isMenuActive('/about-delivery', '/about-us', '/public-contract', '/bonus-account')" :open="isMenuOpen('/about-delivery', '/about-us', '/public-contract', '/bonus-account')">
          <template slot="link-text">Контентные страницы</template>
          <sidenav-router-link to="/about-us" :exact="true">О нас</sidenav-router-link>
          <sidenav-router-link to="/about-delivery" :exact="true">О доставке</sidenav-router-link>
          <sidenav-router-link to="/public-contract" :exact="true">Публичный договор</sidenav-router-link>
          <sidenav-router-link to="/bonus-account" :exact="true">Бонус cчет</sidenav-router-link>
        </sidenav-menu>
      </div>
      <div v-else-if="getProfileData.role === 'financial_manager'">
        <!-- Home -->
        <sidenav-router-link icon="ion ion-md-desktop" to="/" :exact="true">Главная</sidenav-router-link>
        <!-- Goods -->
        <sidenav-menu icon="ion ion-md-pizza" :active="isMenuActive('/products')" :open="isMenuOpen('/products')" @click.native="dataRequest">
          <template slot="link-text">Товары</template>
          <template v-for="(category) of categories">
            <sidenav-router-link :key="category.id" :to="'/category-products/' + category.id" :exact="true">
              <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
            </sidenav-router-link>
          </template>
          <sidenav-router-link to="/products" :exact="true">Все товары</sidenav-router-link>
        </sidenav-menu>

        <!-- Metro stations -->
        <sidenav-router-link icon="ion ion-md-train" to="/metro-stations" :exact="true">Станции метро</sidenav-router-link>

        <!-- Delivery schedule -->
        <sidenav-router-link icon="ion ion-md-gift" to="/delivery-schedules" :exact="true">Доставка</sidenav-router-link>

        <!-- Cities -->
        <sidenav-router-link icon="ion ion-md-map" to="/cities" :exact="true">Города</sidenav-router-link>

        <!-- bonus program -->
        <sidenav-router-link icon="ion ion-md-flame" to="/bonus-program" :exact="true">Бонусная программа</sidenav-router-link>
      </div>
      <div v-else>

        <!-- Home -->
        <sidenav-router-link icon="ion ion-md-desktop" to="/" :exact="true">Главная</sidenav-router-link>

        <!-- Catalog -->
        <sidenav-menu icon="ion ion-ios-book" :active="isMenuActive('/categories', '/subcategories', '/category-subcategories')" :open="isMenuOpen('/categories', '/subcategories', '/category-subcategories')">
          <template slot="link-text">Каталог</template>
          <sidenav-router-link to="/categories" :exact="true">Категории</sidenav-router-link>
          <sidenav-menu icon="ion ion-ios-book" :active="isMenuActive('/subcategories', '/category-subcategories')" :open="isMenuOpen('/subcategories', '/category-subcategories')" @click.native="dataRequest">
            <template slot="link-text">Подкатегории</template>
            <sidenav-router-link to="/subcategories" :exact="true">Все подкатегории</sidenav-router-link>
            <template v-for="(category) of categories">
              <sidenav-router-link :key="category.id" :to="'/category-subcategories/' + category.id" :exact="true">
                <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
              </sidenav-router-link>
            </template>
          </sidenav-menu>
        </sidenav-menu>

        <!-- Goods -->
        <sidenav-menu icon="ion ion-md-pizza" :active="isMenuActive('/products', '/category-products')" :open="isMenuOpen('/products', '/category-products')" @click.native="dataRequest">
          <template slot="link-text">Товары</template>
          <template v-for="(category) of categories">
            <sidenav-router-link :key="category.id" :to="'/category-products/' + category.id" :exact="true">
              <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
            </sidenav-router-link>
          </template>
          <sidenav-router-link to="/products" :exact="true">Все товары</sidenav-router-link>
        </sidenav-menu>
        <!-- Shares -->
        <sidenav-menu icon="ion ion-md-pizza" :active="isMenuActive('/shares', '/category-shares')" :open="isMenuOpen('/shares', '/category-shares')" @click.native="dataRequest">
          <template slot="link-text">Акции</template>
          <template v-for="(category) of categories">
            <sidenav-router-link :key="category.id" :to="'/category-shares/' + category.id" :exact="true">
              <div :class="{'text-danger': category.hidden}">{{ category.name.ru }}</div>
            </sidenav-router-link>
          </template>
          <sidenav-router-link to="/shares" :exact="true">Все акции</sidenav-router-link>
        </sidenav-menu>
        <!-- Users -->
        <sidenav-menu icon="ion ion-ios-contact" :active="isMenuActive('/admin-users', '/app-users')" :open="isMenuOpen('/admin-users', '/app-users')">
          <template slot="link-text">Пользователи</template>
          <sidenav-router-link to="/admin-users" :exact="true">админ панель</sidenav-router-link>
          <sidenav-router-link to="/app-users" :exact="true">приложение</sidenav-router-link>
        </sidenav-menu>

        <!-- Orders -->
        <sidenav-router-link icon="ion ion-ios-basket" to="/orders" :exact="true">Заказы</sidenav-router-link>

        <!-- Restaurants -->
        <sidenav-router-link icon="ion ion-md-restaurant" to="/restaurants" :exact="true">Рестораны</sidenav-router-link>

        <!-- Metro stations -->
        <sidenav-router-link icon="ion ion-md-train" to="/metro-stations" :exact="true">Станции метро</sidenav-router-link>

        <!-- Delivery schedule -->
        <sidenav-router-link icon="ion ion-md-gift" to="/delivery-schedules" :exact="true">Доставка</sidenav-router-link>

        <!-- Cities -->
        <sidenav-router-link icon="ion ion-md-map" to="/cities" :exact="true">Города</sidenav-router-link>

        <!-- bonus program -->
        <sidenav-router-link icon="ion ion-md-flame" to="/bonus-program" :exact="true">Бонусная программа</sidenav-router-link>

        <!-- Content pages -->
        <sidenav-menu icon="ion ion-md-clipboard" :active="isMenuActive('/about-delivery', '/about-us', '/public-contract', '/bonus-account')" :open="isMenuOpen('/about-delivery', '/about-us', '/public-contract', '/bonus-account')">
          <template slot="link-text">Контентные страницы</template>
          <sidenav-router-link to="/about-us" :exact="true">О нас</sidenav-router-link>
          <sidenav-router-link to="/about-delivery" :exact="true">О доставке</sidenav-router-link>
          <sidenav-router-link to="/public-contract" :exact="true">Публичный договор</sidenav-router-link>
          <sidenav-router-link to="/bonus-account" :exact="true">Бонус cчет</sidenav-router-link>
        </sidenav-menu>

        <!-- Content pages -->
        <sidenav-menu icon="ion ion-ios-book" :active="isMenuActive('/dictionaries')" :open="isMenuOpen('/dictionaries')">
          <template slot="link-text">Справочники</template>
          <sidenav-router-link to="/dictionaries/payments" :exact="true">Методы оплаты</sidenav-router-link>
        </sidenav-menu>

        <sidenav-router-link icon="ion ion-md-build" to="/settings" :exact="true">Настройки</sidenav-router-link>
      </div>
    </div>
  </sidenav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  data: () => ({
    categories: []
  }),
  computed: {
    ...mapGetters({
      getProfileData: 'getProfileData',
      getCategories: 'getCategories'
    }),
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },
  mounted () {
    this.dataRequest()
    window.test = this.$el
  },
  methods: {
    ...mapActions({
      categoriesRequest: 'categoriesRequest'
    }),
    async dataRequest () {
      await this.categoriesRequest({ params: { page: 1 } })
      this.categories = JSON.parse(JSON.stringify(this.getCategories))
    },
    isMenuActive (url) {
      for (let i = 0; i < arguments.length; i++) {
        if (this.$route.path.indexOf(url) === 0) {
          return this.$route.path.indexOf(url) === 0
        }
      }

      return false
    },

    isMenuOpen (url) {
      for (let i = 0; i < arguments.length; i++) {
        if (this.$route.path.indexOf(arguments[i]) === 0 && this.orientation !== 'horizontal') {
          return this.$route.path.indexOf(arguments[i]) === 0 && this.orientation !== 'horizontal'
        }
      }

      return false
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>

<style>
.sidenav-inner {
  max-height: 680px;
}
</style>
