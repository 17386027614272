import Vue from 'vue'
import Vuex from 'vuex'
import login from './login/index'
import products from './products/index'
import shares from './shares/index'
import catalog from './catalog/index'
import users from './users/index'
import restaurants from './restaurants/index'
import deliverySchedules from './deliverySchedule/index'
import metroStations from './metroStations/index'
import orders from './orders/index'
import cashback from './cashback/index'
import contentPages from './contentPages/index'
import dashboard from './dashboard/index'
import cities from './cities/index'
import checkout from './checkout'
import settings from './settings'
import dctPayment from './dictionaries/payment'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    products,
    shares,
    catalog,
    users,
    restaurants,
    deliverySchedules,
    metroStations,
    orders,
    cashback,
    contentPages,
    dashboard,
    cities,
    checkout,
    settings,
    dctPayment
  }
})
